import React from "react";

const ms = 1000;
const second = ms;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

const labels = {
    days: {
        single: "dag",
        plural: "dagen"
    },
    hours: {
        single: "uur",
        plural: "uren"
    },
    minutes: {
        single: "minuut",
        plural: "minuten",
    },
    seconds: {
        single: "second",
        plural: "seconden"
    }
}

const calculateTimeLeft = (diff, val, key) => {
    const rest = diff % val;
    const calculated = (diff - rest) / val;
    return {
        [key]: calculated,
        [`${key}Rest`]: rest
    }
}

class CountDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLeft: "",
            timestamp: new Date().getTime(),
            loaded: false
        }
    }

    getRandomBackground = () => `https://source.unsplash.com/${window.innerWidth}x${window.innerHeight}/?vacation,suitcase&${this.state.timestamp}`;

    componentDidMount() {
        setInterval(() => {
            this.setState({ timeLeft: this.calcTimeLeft() });
            if(!this.state.loaded) this.setState({ loaded: true });
        }, 1000);
    }

    calcTimeLeft = () => {
        const target = new Date(2020, 9, 15, 16, 30).getTime();
        const diff = target - new Date().getTime();
        
        let { days, daysRest } = calculateTimeLeft(diff, day, "days");
        let { hours, hoursRest } = calculateTimeLeft(daysRest, hour, "hours");
        let { minutes, minutesRest } = calculateTimeLeft(hoursRest, minute, "minutes");
        let { seconds } = calculateTimeLeft(minutesRest, second, "seconds");

        const returnObj = {};

        if(days > 0) returnObj.days = days;
        if(days > 0 || hours > 0) returnObj.hours = hours;
        if(days > 0 || hours > 0 || minutes > 0) returnObj.minutes = minutes;
        if(days > 0 || hours > 0 || minutes > 0 || seconds > 0) returnObj.seconds = seconds;

        return returnObj;
    }

    render() {
        return (
            <div className="cd" style={{'backgroundImage': `url(${this.getRandomBackground()})`}}>
                <div className="cd__wrapper">
                    <div className="cd__time-left">{!!Object.keys(this.state.timeLeft).length ? Object.keys(this.state.timeLeft).map((key, idx) => {
                        const val = this.state.timeLeft[key];
                        const str = val > 9 ? val : `0${val}`;
                        const labelKey = val === 1 ? 'single' : 'plural';
                        return idx === 0 && val === 0 ? null : (<div className="cd__time-detail"><span className="time">{str}</span> <span className="label">{labels[key][labelKey]}</span></div>)
                    }): this.state.loaded ? (<><div className="congrats">Enjoy!</div><div className="congrats__fade">Enjoy!</div></>) : null}</div>
                </div>
            </div>
        )
    }
}

export default CountDown;